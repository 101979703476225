//=================================================
// Masonry gallery
//=================================================
/*=============================
// Gallery filter
=============================*/
ul.gallery-filter {
	display: table;
	width: 100%;
	text-align: center;
	margin-bottom: -0.526rem;
	@extend .list-unstyled;
	@extend .no-space;

	li {
		display: inline-block;
		margin-left: 0.263rem;
		margin-right: 0.263rem;
		margin-bottom: 0.526rem;
		line-height: 1.31;
		@include lhCrop-b(1.31, 0.75);
		@include lhCrop-a(1.31, 0.75);

		a, span {
			&.btn {
				&.btn-link {
					border: 0;
					padding: 0;
					min-width: auto;

					&:not(.active) {
						color: $masonry_gallery_filter_button_color;
					}
				}
			}
		}

		span {
			&.btn {
				&.btn-link {
					cursor: default;

					&:hover {
						color: $masonry_gallery_filter_button_color;
					}
				}
			}
		}

		a {
			&.click {
				&.btn-link {
					&:after {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						background-color: transparent;
						transition: background-color $transition-s ease-in-out;
					}

					&:not(.active) {
						&:hover {
							color: $masonry_gallery_filter_button_hover_color;
						}
					}
				}
			}

			&.active {
				color: $masonry_gallery_filter_button_active_color;

				&.btn-link {
					&:after {
						background-color: $masonry_gallery_filter_button_active_color;
					}
				}

				&:hover {
					color: $masonry_gallery_filter_button_active_color;

					&.btn-link {
						&:after {
							background-color: $masonry_gallery_filter_button_active_color;
						}
					}
				}
			}
		}
	}
}

/*=============================
// Gallery item wrapper
=============================*/
.gallery-item-wrapper {
	position: relative;
	padding-top: $space_lg;

	.gallery-items {
		margin: -$space_md / 2;

		@media #{$media_xl} {
			margin: -$space_md / 3.3;
		}

		.gallery-item {
			position: relative;
			float: left;
			width: 33.33%;
			padding: $space_md / 2;
			color: inherit;

			@media #{$media_xl} {
				padding: $space_md / 3.3;
				padding: $space_md / 3.3;
			}

			@media #{$media_lg} {
				width: 50%;

				&:nth-child(9n+9) {
					display: none;
				}
			}

			@media #{$media_sm} {
				width: 100%;

				&:nth-child(9n+9) {
					display: block;
				}
			}

			.gallery-item-content {
				display: block;
				position: relative;
				overflow: hidden;

				.img {
					&.object-fit {
						&:before {
							padding-top: 139.11%;
						}
					}

					.object-fit-cover {
						@extend .transition-all;
						transform: perspective(1px) scale(1) translateZ(0);
						backface-visibility: hidden;
					}
				}

				.gallery-hover {
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 1;
					width: 100%;
					padding: $space_sm;
					background-color: $masonry_gallery_item_background_color;
					transform: translateY(100%);
					transition: transform $transition-s ease-in-out;

					.gallery-item-title {
						color: $masonry_gallery_item_title_color;
					}

					.gallery-item-description {
						color: $masonry_gallery_item_description_color;
					}

					.gallery-item-btn {
						> * {
							color: $masonry_gallery_item_btn_color;

							&:after {
								background-color: $masonry_gallery_item_btn_color;
							}

							&:hover {
								color: $masonry_gallery_item_btn_hover_color;

								&:after {
									background-color: $masonry_gallery_item_btn_hover_color;
								}
							}
						}
					}
				}

				&:hover {
					.gallery-hover {
						transform: translateY(0);
					}

					.img {
						.object-fit-cover {
							transform: perspective(1px) scale(1.1) translateZ(0);
						}
					}
				}
			}
		}
	}
}