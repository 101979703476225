//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 8.421rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header,
#menu-modal .modal-header {
    .logo {
        img {
            width: 104px;
        }
    }
}
