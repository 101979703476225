//=================================================
// About
//=================================================

.about-img {
	.img {
		min-height: 181px;

		&.object-fit {
			&:before {
				padding-top: 50.66%;
			}
		}
	}
}
