//=================================================
// Footer
//=================================================

.site-footer {
    background-color: $footer_background_color;

    .wrapper {
        position: relative;
        padding-top: $footer_padding_t;
        padding-bottom: $footer_padding_b;

        .footer {
            .copyright {
                @media #{$media_lg} {
                    text-align: center;
                }
            }

            @media #{$media_lg} {
                .d-flex {
                    > div {
                        margin-bottom: $space_sm;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
